<template>
  <div class="eh-participant-component">
    <fp-prescription-list :participant="userPatientData" sortBy="PrescriptionId" />
  </div>
</template>
<script>
import { UserLogic } from "../../../Logic/Backend/user";
export default {
  name: "Recipes",
  props: {
    participant: Object,
  },
  data() {
    return {
      userPatientData: null,
    };
  },
  watch: {
    async participant() {
      //await this.getRecipes();
      this.userPatientData = await this.getUserBaseData();
    },
  },
  methods: {
    async getUserBaseData() {
      const getUserDataResponse = await UserLogic.getUserData({
        userIds: this.participant.UserId,
      });
      if (!getUserDataResponse.Code) {
        return getUserDataResponse[0];
      } else {
        //TODO: hiba
      }
    },
  },
  async mounted() {
    this.userPatientData = await this.getUserBaseData();
  },
};
</script>
